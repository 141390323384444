import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react'

import { ErrorWrapper } from 'components/common/ErrorWrapper'
import { Error } from 'domain/renderer'
import { NotificationsGroup } from 'components/notifications'

export interface errorState {
  errors: Error[]
  overUseError: boolean
}

type Action =
  | { type: 'ADD_ERROR'; payload: Partial<Error> }
  | { type: 'REMOVE_ERROR'; payload: string }
  | { type: 'CLEAR_ERRORS' }
  | { type: 'SET_OVERUSE_ERROR'; payload: boolean }

const DEFAULT_STATE: errorState = {
  errors: [],
  overUseError: false,
}

const ErrorContext = createContext({
  state: DEFAULT_STATE,
  dispatch: () => {
    return
  },
} as {
  state: errorState
  dispatch: Dispatch<Action>
})

const reducer = (state: errorState, action: Action): errorState => {
  const newState = { ...state }

  switch (action.type) {
    case 'ADD_ERROR':
      newState.errors = [
        ...state.errors,
        { message: '', ...action.payload, id: crypto.randomUUID() },
      ]
      break
    case 'REMOVE_ERROR':
      newState.errors = state.errors?.filter((e) => e.id !== action.payload)
      break
    case 'CLEAR_ERRORS':
      newState.errors = []
      break
    default:
      break
  }

  return {
    ...newState,
  }
}

export function ErrorStateWrapper({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE)

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return (
    <ErrorContext.Provider value={contextValue}>
      <NotificationsGroup />
      <ErrorWrapper>{children}</ErrorWrapper>
    </ErrorContext.Provider>
  )
}

export function useErrorContext() {
  return useContext(ErrorContext)
}
