import Head from 'next/head'

import { ErrorStateWrapper } from 'components/state/ErrorState'

export default function DashboardLayout ({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Head>
        <title>Skippr</title>
        <meta name="description" content="Skippr" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.png" />
      </Head>
      <ErrorStateWrapper>
        <main className="flex flex-col font-sans transition-all bg-[#fafafa] w-full min-h-screen">
          {children}
        </main>
      </ErrorStateWrapper>
    </>
  )
}
