import { useEffect, useRef } from 'react'

export const usePageLoad = (callback: () => void) => {
  const hasRunRef = useRef(false)

  useEffect(() => {
    if (!hasRunRef.current) {
      callback()
      hasRunRef.current = true
    }
  }, [])
}
