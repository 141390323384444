export const baseUrl = process.env.NEXT_PUBLIC_BACKEND_API_HOST
export const apiUrl = `${baseUrl}/api`
export const userId = '63bc2059c9d3dbe880ff8e3e'

const API_V1_PREFIX = '/api/v1'
const HOST_AND_API_PREFIX = baseUrl + API_V1_PREFIX

export const URL_DESIGNS = `${baseUrl}/layout`
export const URL_FIGMA_OAUTH_TOKEN = `${HOST_AND_API_PREFIX}/authentication/figma-login`
export const URL_SIGNUP = `${HOST_AND_API_PREFIX}/authentication`
export const URL_LOGIN = `${HOST_AND_API_PREFIX}/authentication`

export const LAYOUT_ID_HEADER = 'skippr-layout-id'
