import { DevState } from "../MainInfoState"

export interface ThemeState {
  themePickerShow: boolean
  theme: string
  themePrompt: string
  themePicked: boolean
}

export const DEFAULT_THEME_STATE: ThemeState = {
  themePickerShow: false,
  theme: 'default',
  themePrompt: '',
  themePicked: false,
}

export type ThemeAction =
  { type: 'SET_PICKER_SHOW', payload: boolean }
  | { type: 'SET_THEME', payload: string }
  | { type: 'SET_THEME_PROMPT', payload: string }
  | { type: 'SET_THEME_PICKED', payload: boolean }
  | { type: 'RESET_THEME_PICKER' }

export const reducerThemeFunction = (state: DevState, action: ThemeAction) => {
  let newState = { ...state }
  let changed = true

  switch (action.type) {
    case 'SET_PICKER_SHOW':
      newState.themePickerShow = action.payload
      break
    case 'SET_THEME':
      newState.theme = action.payload
      break
    case 'SET_THEME_PROMPT':
      newState.themePrompt = action.payload
      break
    case 'SET_THEME_PICKED':
      newState.themePicked = action.payload
      break
    case 'RESET_THEME_PICKER':
      newState = { ...state, ...DEFAULT_THEME_STATE }
      break
    default:
      changed = false
      break
  }

  return { newState, changed }
}
