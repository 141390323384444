import {
  Dispatch,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useReducer,
} from 'react'

import { getCreditsFE } from 'helpers/getCredits'
import {
  DEFAULT_THEME_STATE,
  ThemeAction,
  ThemeState,
  reducerThemeFunction,
} from './partials/ThemePartialState'
import { usePageLoad } from 'helpers/usePageLoad'

const DANGER_COLOR = '#F56565'
const WARNING_COLOR = '#ECC94B'

export const LOW_CREDITS = 10
export const VERY_LOW_CREDITS = 5

export interface DevState extends ThemeState {
  credits: number
  creditColor?: string
  loading: boolean
  withFlows: boolean
}

const DEFAULT_STATE: DevState = {
  credits: 100,
  loading: true,
  withFlows: false,
  // theme picker
  ...DEFAULT_THEME_STATE,
}

type Action =
  | { type: 'SET_CREDITS'; payload: number }
  | { type: 'REMOVE_CREDITS'; payload?: number }
  | { type: 'SET_WITH_FLOWS', payload: boolean }
  // theme picker
  | ThemeAction

const MainInfoContext = createContext({
  state: DEFAULT_STATE,
  dispatch: () => {
    return
  },
} as {
  state: DevState
  dispatch: Dispatch<Action>
})

const reducer = (state: DevState, action: Action): DevState => {
  let newState = { ...state }

  switch (action.type) {
    case 'SET_CREDITS':
      newState.credits = action.payload
      if (state.loading === true) newState.loading = false
      break
    case 'REMOVE_CREDITS':
      newState.credits = state.credits - (action.payload ?? 1)
      break
    case 'SET_WITH_FLOWS':
      newState.withFlows = action.payload
      break
    default:
      const { newState: themeState, changed } = reducerThemeFunction(state, action)
      if (changed) newState = { ...state, ...themeState }
      break
  }

  return newState
}

export function MainInfoWrapper ({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE)

  const creditColor = useMemo(() => {
    if (state.credits <= VERY_LOW_CREDITS) return DANGER_COLOR
    if (state.credits <= LOW_CREDITS) return WARNING_COLOR
    return undefined
  }, [state.credits])

  const contextValue = useMemo(
    () => ({
      state: {
        ...state,
        creditColor,
      },
      dispatch,
    }),
    [creditColor, state],
  )

  usePageLoad(() => {
    getCreditsFE().then((credits) => {
      dispatch({ type: 'SET_CREDITS', payload: credits })
    })
  })

  return (
    <MainInfoContext.Provider value={contextValue}>{children}</MainInfoContext.Provider>
  )
}

export function useMainInfoState () {
  return useContext(MainInfoContext)
}
