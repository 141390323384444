import { ReactNode, useMemo, useState } from 'react'
import classNames from 'classnames'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import { useErrorContext } from 'components/state/ErrorState'

export const ErrorWrapper = ({ children }: { children: ReactNode }) => {
  const {
    state: { errors },
    dispatch,
  } = useErrorContext()

  const [hovering, setHovering] = useState(false)

  const collapsable = useMemo(() => errors.length > 1, [errors])

  return (
    <>
      {errors.length > 0 && (
        <div className="fixed bottom-8 right-8 z-50 pointer-events-none">
          <div
            className="flex flex-col items-center text-center space-y-1.5 relative w-[220px]"
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
          >
            {collapsable && (
              <div
                className={classNames(
                  'absolute right-0 left-0  flex justify-center z-50 transition-all',
                  {
                    '-bottom-3': !hovering,
                    '-bottom-8': hovering,
                  },
                )}
              >
                <div className="bg-red-600 rounded-full py-1.5 px-3 text-white font-medium text-xs fade-in shadow">
                  {errors.length} errors
                </div>
              </div>
            )}
            {errors.reverse().map((error, index) => (
              <div
                style={{ bottom: hovering ? 0 : `${index * 8}px` }}
                className={classNames(
                  'bg-white p-4 rounded-xl shadow-lg hover:shadow-xl border text-sm flex flex-col w-[220px] fade-in-up pointer-events-auto space-y-5 hover:border-stone-300 right-0 transition-all',
                  {
                    absolute: !hovering && collapsable,
                  },
                )}
                key={error.id}
              >
                <div className="space-y-3 flex flex-col items-center">
                  <div className="h-11 w-11 bg-yellow-200 rounded-full flex items-center justify-center">
                    <ExclamationTriangleIcon className="h-6 text-stone-500" />
                  </div>
                  <div className="space-y-2">
                    <h4 className="font-medium text-base leading-tight">{error.title}</h4>
                    <p
                      className={
                        error.code
                          ? 'bg-gray-50 p-1 border border-gray-100 rounded-md font-mono'
                          : ''
                      }
                    >
                      {error.message}
                    </p>
                  </div>
                </div>

                <button
                  onClick={() => dispatch({ type: 'REMOVE_ERROR', payload: error.id })}
                  className="p-2.5 active rounded-md bg-ui-gray hover:bg-stone-200 text-stone-700 font-medium text-xs"
                >
                  Close
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {children}
    </>
  )
}
