import { getAccessToken } from '@auth0/nextjs-auth0'
import axios from 'axios'
import { apiUrl } from 'utils/api-url'
import Cookies from 'cookies'
import jsCookie from 'js-cookie'

export const getCredits = async (context: any, skipCookie?: boolean) => {
  const { req, res } = context
  const cookies = new Cookies(req, res)

  const myCreditCookie = cookies.get('myCredits')
  if (myCreditCookie && !skipCookie) {
    return parseInt(myCreditCookie)
  }

  const { accessToken } = await getAccessToken(context.req, context.res)

  const response = await axios.get(`${apiUrl}/users/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })

  if (response.status !== 200 || !response.data) {
    return 100
  }

  cookies.set('myCredits', response.data.credits, {
    path: '/',
    httpOnly: false,
    // calculate ms to expire in 1 hour
    expires: new Date(Date.now() + 60 * 60 * 1000),
  })

  return response.data.credits
}

export const getCreditsFE = async (skipCookie?: boolean) => {
  // const myCreditCookie = jsCookie.get('myCredits')
  // if (myCreditCookie && !skipCookie) {
  //   return parseInt(myCreditCookie)
  // }

  try {
    const response = await axios.get(`/api/users`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (response.status !== 200 || !response.data) return 100

    jsCookie.set('myCredits', response.data.credits, {
      path: '/',
      httpOnly: false,
      // calculate ms to expire in 1 hour
      expires: new Date(Date.now() + 60 * 60 * 1000),
    })
    return response.data.credits
  } catch (err) {
    return 100
  }
}
