import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { Analytics } from '@vercel/analytics/react'
import { UserProvider } from '@auth0/nextjs-auth0/client'

import Layout from 'components/common/Layout'
import { MainInfoWrapper } from 'components/renderer/state/MainInfoState'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Image from 'next/image'
import { ThemeCreator } from 'components/ThemeCreator'
import { useRouter } from 'next/router'

const mobileFriendlyPages = ['/view/[id]']

const MobileDetector = () => {
  const [copied, setCopied] = useState(false)
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const router = useRouter()

  const copyLink = () => {
    if (timeout.current) clearTimeout(timeout.current)
    navigator.clipboard.writeText(window.location.href)
    setCopied(true)
    timeout.current = setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  if (mobileFriendlyPages.includes(router.pathname)) return null

  return (
    <div className='pt-2 pb-16 px-4 fixed top-0 left-0 right-0 bottom-0 bg-white z-[120] flex flex-col gap-4 items-center justify-center'>
      <div className='rounded-lg bg-accent text-white text-sm flex items-center justify-center active transition-all w-12 h-12 pointer-events-none fixed bottom-[5vh] right-[10vw] opacity-[0.075]' style={{ transform: 'scale(8) rotate(-10deg)' }}>
        <img src="/logoLight.svg" alt="" />
      </div>
      <div className='rounded-md bg-accent text-white text-sm flex items-center justify-center active transition-all w-12 h-12 pointer-events-none'>
        <Image src="/logoLight.svg" width={24} height={24} alt='skippr logo' />
      </div>
      <h1 className='max-w-[500px] text-4xl text-center font-semibold mb-4'>We don&apos;t have <br />a mobile version<br />...yet</h1>
      <p className='max-w-[500px] text-justify'>Hey there! Thanks for visiting Skippr! We&apos;re currently working on making it mobile-friendly, so for now please use it on desktop or laptop.</p>
      <p className='max-w-[500px] text-justify'>If you have any questions, feel free to reach out. Stay tuned for updates, and have a fantastic day!</p>
      <div className='flex items-center justify-center gap-4 flex-wrap mt-4'>
        <button onClick={copyLink} className="py-3 px-5 rounded border border-accent bg-accent text-sm font-medium text-white active disabled:opacity-60 hover:bg-opacity-90 disabled:hover:bg-opacity-100 w-36 text-center">
          {copied ? 'Copied!' : 'Copy link'}
        </button>
        <a href="mailto:contact@skippr.com?subject=Mobile version response" className="py-3 px-5 rounded border border-gray-light text-sm font-medium text-gray-dark active disabled:opacity-60 hover:bg-opacity-90 disabled:hover:bg-opacity-100 w-36 text-center">Contact us</a>
      </div>
    </div>
  )
}

export default function App ({ Component, pageProps }: AppProps) {
  const [dimensions, setDimensions] = useState({
    height: 1000,
    width: 1000
  })

  useEffect(() => {
    function handleResize () {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)

    }
  }, [])

  const show = useMemo(() => {
    return dimensions.width < 800
  }, [dimensions])

  return (
    <>
      <UserProvider>
        <MainInfoWrapper>
          {/* <ThemeCreator /> */}
          {show && <MobileDetector />}
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </MainInfoWrapper>
      </UserProvider>
      <Analytics />
    </>
  )
}
