import { useEffect, useMemo, useRef, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ClockIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useUser } from '@auth0/nextjs-auth0/client'

import { useErrorContext } from 'components/state/ErrorState'
import {
  LOW_CREDITS,
  VERY_LOW_CREDITS,
  useMainInfoState,
} from 'components/renderer/state/MainInfoState'

const Notification = ({
  text,
  type,
  link,
  linkText,
  buttonText,
  onClick,
  onClose,
  children,
  show = true,
}: {
  text?: string | React.ReactNode
  type: 'danger' | 'warning' | 'success' | 'info'
  link?: string
  linkText?: string
  buttonText?: string
  onClick?: () => void
  onClose?: () => void
  children?: React.ReactNode
  show?: boolean
}) => {
  const [innerShow, setInnerShow] = useState(true)

  const showNotification = useMemo(() => {
    return innerShow && show
  }, [show, innerShow])

  const notificationColor = useMemo(() => {
    if (type === 'danger') return { border: '#E53E3E', bg: '#FFF5F5' }
    if (type === 'warning') return { border: '#ECC94B', bg: '#FFFAF0' }
    return { border: '#E53E3E', bg: '#FFF5F5' }
  }, [type])

  const closeClick = () => {
    setInnerShow(false)
    onClose && onClose()
  }

  if (!showNotification) return null

  return (
    <div
      className="space-y-2 border border-1 p-4 rounded-lg fade-in-down w-[725px] fade-in-down"
      style={{ borderColor: notificationColor.border, background: notificationColor.bg }}
    >
      <div className="flex items-center justify-between">
        {children}
        {onClose && (
          <button
            onClick={closeClick}
            className="inline-block align-middle ml-1 text-gray-400 hover:text-black"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        )}
      </div>
      {text && <div className="flex-1">{text}</div>}
      <div className="shrink-0 flex items-center justify-end space-x-6">
        {link && (
          <Link
            href={link}
            className="text-gray-dark font-medium hover:text-black active"
          >
            {linkText}
          </Link>
        )}
        {buttonText && (
          <button
            onClick={onClick}
            className="text-white px-4 py-2 rounded font-medium hover:opacity-75 transition-all active"
            style={{ background: 'linear-gradient(360deg, #FF00C7 0%, #F09 100%)' }}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  )
}

export const CreditNotification = () => {
  const { user } = useUser()
  const {
    state: { credits, loading, creditColor },
  } = useMainInfoState()
  const [show, setShow] = useState(false)
  const router = useRouter()

  useEffect(() => {
    if (credits < LOW_CREDITS) setShow(true)
    else setShow(false)
  }, [credits])

  const type = useMemo(() => {
    if (credits < VERY_LOW_CREDITS) return 'danger'
    if (credits < LOW_CREDITS) return 'warning'
    return 'info'
  }, [credits])

  const badgeColor = useMemo(() => {
    if (type === 'danger') return '#FED7D7'
    if (type === 'warning') return '#FEEBCB'
    return '#F0FFF4'
  }, [type])

  if (router.pathname !== '/' || loading || !user) return null

  return (
    <div className="flex justify-center">
      <Notification
        text={
          type === 'danger' ? (
            <>
              <p>
                Oops! You&apos;re nearly out of tokens. Good news! We have a one-time
                offer of extra free tokens for our pre-beta community members.
              </p>
              <p>
                <a
                  href="https://airtable.com/appZ0SInpeIHSHyjH/shrfzwChByjvSZr7s"
                  className="underline underline-offset-4 hover:text-accent active"
                >
                  Fill out this form
                </a>{' '}
                to grab yours - availability is limited!
              </p>
            </>
          ) : (
            `Uh-oh! Looks like you're low on credits.`
          )
        }
        type={type}
        link="/profile"
        linkText="Learn more"
        show={show}
        onClose={() => setShow(false)}
      >
        <div
          className="rounded-md px-3 py-[6px] leading-tight font-medium"
          style={{ background: badgeColor }}
        >
          <span
            className="text-lg tracking-tight text-black"
            style={{ color: creditColor }}
          >
            {credits}
          </span>{' '}
          credits left
        </div>
      </Notification>
    </div>
  )
}

export const OveruseNotification = () => {
  const ref = useRef<Element | null>(null)
  const [mounted, setMounted] = useState(false)
  const {
    state: { overUseError },
    dispatch,
  } = useErrorContext()

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>('#notification-div')
    setMounted(true)
  }, [])

  const onClose = () => {
    dispatch({ type: 'SET_OVERUSE_ERROR', payload: false })
  }

  if (!mounted || !ref.current) return <></>

  return (
    <Notification
      type="danger"
      onClose={onClose}
      show={overUseError}
      text={
        'Wow! Looks like you&apos;ve been having a blast with the system and got a little carried away. To keep things smooth, please cool down for 60 seconds before creating your next project.'
      }
    >
      <div>
        <div
          className="flex items-center rounded-full px-3 py-[6px] text-sm leading-tight font-medium text-[#C53030]"
          style={{ background: '#FED7D7' }}
        >
          <ClockIcon className="inline-block h-5 w-5 mr-2" />
          Overuse Alert
        </div>
      </div>
    </Notification>
  )
}

export const NotificationsGroup = () => {
  return (
    <div
      id="notification-div"
      className="w-auto fixed top-3 z-[100]"
      style={{ left: 'max(98px, 11vw)', right: 'max(98px, 11vw)' }}
    >
      <CreditNotification />
      <OveruseNotification />
    </div>
  )
}
